import React, { useContext } from 'react';

const ToastContext = React.createContext();

export const useToastContext = () => {
  const context = useContext(ToastContext);

  if (context === undefined) {
    throw new Error('useToastContext error');
  }
  return context;
};

export default ToastContext;
