import { Typography, MUI } from '@promptlylabs/skeleton';
import { LangSelect } from '@promptlylabs/ui-kit';
import { fetchLanguageOptions } from 'actions/settings';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getLanguageOptions } from 'selectors/_selected_settings';
import styled from 'styled-components';

const FooterLoginWrapper = styled.div`
  display: flex;
  :last-child {
    justify-content: flex-end;
    align-items: center;
  }
  @media (max-width: 576px) {
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
`;

class FooterLogin extends PureComponent {
  state = { loading: true };

  componentDidMount() {
    Promise.resolve(this.props.fetchLanguageOptions());

    setTimeout(() => {
      window.i18next.changeLanguage(this.props.language);
      this.setState({ loading: false });
    }, 0);
  }

  getSelectedLang = (lang, languageOptions) => {
    const selectedLangExists = languageOptions.map(l => l.value).includes(lang);
    if (selectedLangExists) {
      return lang;
    }
    return languageOptions.length ? languageOptions[0].value : undefined;
  };

  handleLangChange = (lang, props) => {
    setTimeout(() => {
      window.i18next.changeLanguage(lang);
    }, 0);

    localStorage.setItem('lang', lang);
    if (props.changePreferences) {
      props.changeRegionalPreferences(props.preferencesURL, lang, props.timeZone);
    }
  };

  render() {
    const { t, languageOptions } = this.props;
    const { loading } = this.state;
    const { Link } = MUI;

    return (
      <FooterLoginWrapper>
        <div className="prom-login-footer">
          <Typography sx={{ fontSize: '0.75rem' }}>
            {t('All rights reserved to Promptly Health ©{{date}}', { date: new Date().getFullYear() })}
          </Typography>
          <Link
            href="https://promptlyhealth.com/en/legal/privacy-policy"
            sx={{ fontSize: '0.75rem' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Privacy policy & Terms and conditions')}
          </Link>
        </div>
        {!loading && (
          <LangSelect
            options={languageOptions}
            handleChange={r => this.handleLangChange(r.value, this.props)}
            selected={this.getSelectedLang(this.props.language, languageOptions)}
          />
        )}
      </FooterLoginWrapper>
    );
  }
}

FooterLogin.defaultProps = {
  language: localStorage.getItem('lang')
};

FooterLogin.propTypes = {
  language: PropTypes.string,
  fetchLanguageOptions: PropTypes.func.isRequired,
  languageOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  languageOptions: getLanguageOptions
});

export default connect(mapStateToProps, { fetchLanguageOptions })(FooterLogin);
