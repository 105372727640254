import { SERVER_ERROR } from '../constants/messages';
import getCsrfTokenValue from './getCsrfTokenValue';
import { checkTargetApp } from './app';

const csrftoken = getCsrfTokenValue();

export const displayNotificationError = context => {
  if (!context) return;
  context.addToast(SERVER_ERROR);
};

const fetchApi = ({
  url,
  formBody,
  method = 'POST',
  context = undefined,
  contentType,
  successFunc,
  serverErrorsFunc,
  requestWith,
  errorFunc
}) =>
  fetch(url, {
    credentials: 'include',
    method,
    mode: 'same-origin',
    headers: {
      'Content-Type': contentType !== undefined ? contentType : 'application/x-www-form-urlencoded;charset=UTF-8',
      'X-CSRFToken': csrftoken,
      'X-Promptly-Version': checkTargetApp(window.FE_VERSION, window),
      'X-Requested-With': requestWith ? 'XMLHttpRequest' : '',
      'Accept-Language': window.localStorage.getItem('lang') || navigator.language
    },
    body: formBody
  })
    .then(response => {
      if (response.status === 200 || response.status === 400) {
        response.json().then(data => {
          if (response.status === 200) {
            if (typeof successFunc === 'function') {
              successFunc(data, context);
            }
          } else if (typeof successFunc === 'function') {
            serverErrorsFunc(data, context);
          }
        });
      } else {
        displayNotificationError(context);
        if (typeof errorFunc === 'function') {
          errorFunc();
        }
      }
    })
    .catch(response => {
      displayNotificationError(context);
      if (typeof errorFunc === 'function') {
        errorFunc(response);
      }
      if (typeof window.setAppOffline === 'function') {
        window.setAppOffline();
      }
    });

export default fetchApi;
