import PropTypes from 'prop-types';
import React from 'react';
import { getLogoUrl } from 'utils/static';

const HeaderLogin = props => {
  const { className } = props;
  return (
    <div className={className}>
      <img src={getLogoUrl('assets/images/prom-logo-blue.svg')} alt="Promptly" />
    </div>
  );
};

HeaderLogin.defaultProps = {
  className: 'prom-login-header'
};

HeaderLogin.propTypes = {
  className: PropTypes.string
};

export default HeaderLogin;
