"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var skeleton_1 = require("@promptlylabs/skeleton");
var react_1 = require("react");
var VisibilityIcon = skeleton_1.Icons.VisibilityIcon, VisibilityOffIcon = skeleton_1.Icons.VisibilityOffIcon;
var PasswordInput = function (_a) {
    var label = _a.label, error = _a.error, helperText = _a.helperText, onChange = _a.onChange, value = _a.value, placeholder = _a.placeholder;
    var _b = (0, react_1.useState)(false), showPassword = _b[0], setShowPassword = _b[1];
    var handleClickShowPassword = function () { return setShowPassword(!showPassword); };
    var handleMouseDownPassword = function (event) {
        event.preventDefault();
    };
    return ((0, jsx_runtime_1.jsx)(skeleton_1.Input, { placeholder: placeholder, id: "password", label: label, fullWidth: true, InputProps: {
            endAdornment: ((0, jsx_runtime_1.jsx)(skeleton_1.InputAdornment, __assign({ position: "end", "aria-label": "toggle password visibility", onClick: function () {
                    handleClickShowPassword();
                }, onMouseDown: handleMouseDownPassword, sx: { cursor: 'pointer' } }, { children: showPassword ? (0, jsx_runtime_1.jsx)(VisibilityOffIcon, {}, void 0) : (0, jsx_runtime_1.jsx)(VisibilityIcon, {}, void 0) }), void 0))
        }, value: value, type: showPassword ? 'text' : 'password', error: error, helperText: helperText, onChange: onChange }, void 0));
};
exports.default = PasswordInput;
