import React from 'react';
import PropTypes from 'prop-types';
import HeaderLogin from '../HeaderLogin/index';

const ContainerAuth = ({ className, t, children }) => (
  <div className="container">
    <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12 clearfix login-forms-wrapper">
      <HeaderLogin className={className} t={t} />
      {children}
    </div>
  </div>
);

ContainerAuth.propTypes = {
  className: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired
};

export default ContainerAuth;
