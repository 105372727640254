"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var skeleton_1 = require("@promptlylabs/skeleton");
var Snackbar = skeleton_1.MUI.Snackbar, Alert = skeleton_1.MUI.Alert;
var ToastItem = function (_a) {
    var t = _a.t, onClose = _a.onClose, width = _a.width, verticalAlign = _a.verticalAlign;
    return ((0, jsx_runtime_1.jsx)(Snackbar, __assign({ open: true, id: t.id, onClose: onClose, sx: { width: width || '320px' }, anchorOrigin: {
            vertical: verticalAlign || 'top',
            horizontal: 'center'
        }, autoHideDuration: 3000 }, { children: (0, jsx_runtime_1.jsx)(Alert, __assign({ onClose: onClose, severity: t.type, sx: { width: '100%', fontWeight: '400' }, variant: "filled", icon: false }, { children: t.message }), void 0) }), t.id));
};
exports.default = ToastItem;
