import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addToast, removeToast } from '../../actions/toasts';
import '../../assets/css/transitions.scss';
import ToastItem from './ToastItem';

class ToastsSystem extends React.PureComponent {
  addToast = ({ message, type }) => {
    this.props.addToast({ message, type });
  };

  removeToast = (event, reason, id) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.removeToast(id);
  };

  buildItems = ({ toasts }) => {
    if (!toasts) {
      return null;
    }

    return toasts.map(t => (
      <div key={t.id}>
        <ToastItem
          t={t}
          width={this.props.width}
          verticalAlign={this.props.verticalAlign}
          onClose={(event, reason) => this.removeToast(event, reason, t.id)}
        />
      </div>
    ));
  };

  render = () => this.buildItems(this.props);
}

ToastsSystem.defaultProps = {
  width: null,
  verticalAlign: null
};

ToastsSystem.propTypes = {
  addToast: PropTypes.func.isRequired,
  removeToast: PropTypes.func.isRequired,
  width: PropTypes.string,
  verticalAlign: PropTypes.string
};

const mapDispatchToProps = dispatch => bindActionCreators({ removeToast, addToast }, dispatch);

const mapStateToProps = state => ({
  toasts: state.toasts
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ToastsSystem);
