import PropTypes from 'prop-types';
import React from 'react';
import { getLogoUrl } from 'utils/static';

const FlyerAuth = props => (
  <div className="login-background">
    <div className="login-flyer-wrapper">
      <div className="flyer-header">
        <img
          style={{
            height: '30px',
            marginBottom: '10px'
          }}
          src={getLogoUrl('assets/images/logo.svg')}
          alt="Promptly"
        />
        <p className="flyer-content__info">
          {props.t('Know more in ')}
          <a rel="noopener noreferrer" target="_blank" href="https://promptlyhealth.com">
            {'www.promptlyhealth.com'}
          </a>
        </p>
      </div>
    </div>
  </div>
);

FlyerAuth.propTypes = {
  t: PropTypes.func.isRequired
};

export default FlyerAuth;
