import { ADD_TOAST, REMOVE_TOAST } from '../constants/actionTypes';

export const addToast = ({ message, type, id = undefined }) => ({
  type: ADD_TOAST,
  payload: { id: id === undefined ? new Date().getTime().toString(16) : id, message, type }
});

export const removeToast = id => ({
  type: REMOVE_TOAST,
  payload: { id }
});
