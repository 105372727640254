import PropType from 'prop-types';
import React from 'react';
import ToastContext from '../../contexts/ToastsContext';
import ToastsSystem from './ToastsSystem';

/*
This Provider is used to pass notifications interface globally to the aplication

When we want to use in a view follow the steps:

  1) Wrapp all app with ToastsProvider like this;
    <ToastsProvider>
      <App />
    </ToastsProvider>

  2) To consume the provider and use methods use this ways:
    2.1) Import ToastContext
    2.2) Change Component
      <App>
        <ToastContext.Consumer>
          {(context) => (
            <div>
              <button
                className="btn-info"
                onClick={() => context.addToast(
                  { message: 'test', type: 'error' }
                )}
              >
                Send toast
              </button>
            </div>
          )}
        </ToastContext.Consume>
      <App>
*/

class ToastsProvider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toastSystem = React.createRef();
  }

  getToastInstance = () => this.toastSystem.current;

  addToast = ({ message, type }) => {
    this.getToastInstance().addToast({ message, type });
  };

  removeToast = id => {
    this.getToastInstance().removeToast(id);
  };

  render = () => {
    const providerValues = {
      addToast: this.addToast,
      removeToast: this.removeToast
    };

    return (
      <ToastContext.Provider value={providerValues}>
        {this.props.children}
        <ToastsSystem ref={this.toastSystem} width={this.props.width} verticalAlign={this.props.verticalAlign} />
      </ToastContext.Provider>
    );
  };
}

ToastsProvider.defaultProps = {
  width: null,
  verticalAlign: null
};

ToastsProvider.propTypes = {
  children: PropType.oneOfType([PropType.element, PropType.array]).isRequired,
  width: PropType.string,
  verticalAlign: PropType.string
};

export default ToastsProvider;
