import _ from 'lodash';
import { createSelector } from 'reselect';

export const getSettings = state => state.settings;

export const getRegionalSettings = createSelector([getSettings], settings => settings.regional);

export const getLanguageOptions = createSelector([getSettings], settings => settings.languageOptions);

export const getLanguage = createSelector([getRegionalSettings], regional => regional.language);

export const getTimeZone = createSelector([getRegionalSettings], regional => regional.timeZone);

export const getClinicalTrials = createSelector([getSettings], settings => {
  const groupedItems = _(settings.clinicalTrials)
    .groupBy(item => item.patient_disease.disease.name)
    .map((value, key) => ({ category: key, items: value }))
    .value();
  return groupedItems;
});

export const getUnansweredClinicalTrials = createSelector([getSettings], settings =>
  settings.clinicalTrials.filter(ct => !ct.agreed)
);

export const isThereClinicalTrialsToAnswer = createSelector([getSettings], settings =>
  settings.clinicalTrials.some(ct => !ct.agreed)
);

export const getContextScreen = createSelector([getSettings], settings => settings.contextScreen);

export const getnavigationsPreferences = createSelector([getSettings], settings => settings.navigationsPreferences);

export const getTodoListPreferences = createSelector([getnavigationsPreferences], preferences => preferences.todoList);
